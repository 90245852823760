import { withAuthenticator } from "@aws-amplify/ui-react";
import { Space, Table } from "antd";
import { SectionHeading } from "components/misc/Headings";
import AppForm from "pham-sushi/components/AppForm";
import { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { generateClient } from "aws-amplify/api";
import { listAnnouncements } from "graphql/queries";
import {
  createAnnouncement,
  deleteAnnouncement,
  updateAnnouncement,
} from "graphql/mutations";
import { ReactComponent as DeleteIcon } from "feather-icons/dist/icons/trash-2.svg";
import { ReactComponent as EditIcon } from "feather-icons/dist/icons/edit.svg";
import ReactModalAdapter from "helpers/ReactModalAdapter";
import {
  OutlinePrimaryButton,
  OutlineSecondaryButton,
} from "components/misc/Buttons";

const client = generateClient();

const TwoColumn = tw.div`flex gap-16 flex-wrap`;
const Column = tw.div`flex-1 w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const StyledTable = styled(Table)`
  .ant-table {
    ${tw`bg-transparent mt-8 text-gray-600 text-sm md:text-base lg:text-xl font-medium leading-relaxed`}
  }
  .ant-table-thead .ant-table-cell {
    ${tw`bg-black-100 text-white`}
  }
  .ant-table-row,
  .ant-table-placeholder {
    &:hover > td.ant-table-cell {
      ${tw`bg-transparent`}
    }
  }
  .ant-table-placeholder > td.ant-table-cell .ant-empty-description {
    ${tw`text-white`}
  }
  .ant-pagination-item,
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    overflow: hidden;
    border-color: inherit;
    ${tw`bg-transparent`}
    &>* {
      ${tw`text-white`}
    }
  }
  .ant-pagination-item-active,
  .ant-pagination-item-active:focus-visible,
  .ant-pagination-item-active:hover,
  .ant-pagination-item:hover,
  .ant-pagination-prev:not(.ant-pagination-disabled):hover,
  .ant-pagination-next:not(.ant-pagination-disabled):hover {
    border-color: inherit;
    ${tw`bg-black-100`}
    &>* {
      ${tw`bg-black-100 text-white`}
    }
  }
`;
const Heading = tw(
  SectionHeading
)`text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight max-w-38r`;
const ActionButton = tw.button`w-6 h-6`;
const ButtonWrapper = tw.div`flex flex-wrap justify-center gap-4 pt-16`;
const SubmitButton = tw(OutlineSecondaryButton)`inline-block`;
const CancelButton = tw(OutlinePrimaryButton)`inline-block`;
const StyledModal = styled(ReactModalAdapter)`
  &.deleteModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.deleteModal__content {
    ${tw`xl:mx-auto p-16 m-4 sm:m-16 max-w-screen-sm absolute inset-0 bottom-auto flex flex-col rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full text-lg text-white text-center`}
  }
`;
const ContentCell = tw.div`whitespace-pre-line`;

const fields = [
  {
    type: "textarea",
    name: "content",
    placeholder: "Announcement content",
    required: true,
  },
];
const Announcements = () => {
  const columns = [
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      render: (value) => <ContentCell>{value}</ContentCell>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <ActionButton
            onClick={() => handleSelectToDelete(record)}
            disabled={isLoading}
          >
            <DeleteIcon />
          </ActionButton>
          <ActionButton
            onClick={() => handleSelectToEdit(record)}
            disabled={isLoading}
          >
            <EditIcon />
          </ActionButton>
        </Space>
      ),
    },
  ];

  const [data, setData] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [isReload, setIsReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState();

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  useEffect(() => {
    let isUnmounted = false;
    // List all items
    const getData = async () => {
      const allAnnouncements = await client.graphql({
        query: listAnnouncements,
      });
      const announcements =
        allAnnouncements?.data?.listAnnouncements?.items || [];
      if (!isUnmounted) {
        setData(
          announcements.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          )
        );
      }
    };
    setIsLoading(true);
    getData();
    setIsLoading(false);
    return () => {
      isUnmounted = true;
    };
  }, [isReload]);

  const handleSubmit = async (value) => {
    if (isLoading) return;
    setIsLoading(true);
    await client.graphql({
      query: selectedItem?.id ? updateAnnouncement : createAnnouncement,
      variables: {
        input: {
          id: selectedItem?.id,
          ...value,
        },
      },
      authMode: "userPool",
    });
    setIsReload((prevState) => !prevState);
    setIsLoading(false);
    setSelectedItem();
    return true;
  };

  const handleSelectToEdit = async (item) => {
    setSelectedItem(item);
  };

  const handleSelectToDelete = (item) => {
    setDeleteItem(item);
    setModalIsOpen(true);
    setSelectedItem();
  };

  const handleDelete = async () => {
    if (isLoading) return;
    setIsLoading(true);
    await client.graphql({
      query: deleteAnnouncement,
      variables: {
        input: {
          id: deleteItem.id,
        },
      },
      authMode: "userPool",
    });
    setIsReload((prevState) => !prevState);
    setIsLoading(false);
    setModalIsOpen(false);
    setDeleteItem();
  };

  const handleDeleteCancel = () => {
    setModalIsOpen(false);
    setDeleteItem();
  };

  return (
    <>
      <Heading>Announcements</Heading>
      <TwoColumn>
        <Column>
          {data && (
            <StyledTable dataSource={data} columns={columns} rowKey="id" />
          )}
        </Column>
        <Column>
          <AppForm
            fields={fields}
            onSubmit={handleSubmit}
            cancelButtonText="Cancel"
            values={selectedItem}
            onCancel={() => setSelectedItem()}
          />
        </Column>
      </TwoColumn>
      <StyledModal
        closeTimeoutMS={300}
        className="deleteModal"
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className="content">
          Are you sure want to delete "{deleteItem?.content}"?
        </div>
        <ButtonWrapper>
          <CancelButton type="button" onClick={handleDeleteCancel}>
            No
          </CancelButton>
          <SubmitButton type="submit" onClick={handleDelete}>
            Yes
          </SubmitButton>
        </ButtonWrapper>
      </StyledModal>
    </>
  );
};
export default Announcements;
