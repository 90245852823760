import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPadding2Xl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import headerBgImage from "images/sushi/header-bg.png";
import { LinkButton } from "components/misc/Buttons";
import { combos } from "pham-sushi/data/combo";

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = styled(SectionHeading)`
    ${tw`mx-auto relative font-bold text-gray-600`}
    ${css`
        &::before {
            background-image: url(${headerBgImage});
            background-position: left top;
            background-repeat: no-repeat;
            background-size: auto 100%;
            content: '';
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 246%;
            top: -90%;
            left: 30%;
        }
    `}
`;
const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.div)`rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0 relative pt-64`;
const CardImageContainer = styled.div`
  ${props => css`
    background-image: url("${props.imageSrc}");
    background-position: ${props.bgPosition};
    background-size: ${props.bgSize};
  `}
  ${tw`bg-no-repeat absolute top-0 left-0 right-0 bottom-0`}
`;
const CardRatingContainer = tw.div`leading-none inline-flex text-gray-600 mb-4 items-end`;
const CardRating = styled.div`
  ${tw`mx-6 flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;
const CardText = tw.div`px-4 py-8 text-gray-600 text-center relative`;
const CardTitle = tw.h5`text-xl font-semibold text-white`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-500 text-center`;
const CardPrice = tw.p`mt-4 mx-6`;
const Description = tw.p`mt-4 max-w-4xl mx-auto lg:mt-16 text-center lg:text-2xl leading-relaxed text-gray-400 tracking-wide`;
const ButtonContainer = tw.div`text-center mt-16`;

export default ({
    heading = "Checkout the Menu",
    description = "Offering an authentic taste of Japan, the combo at Pham Sushi is elevated and innovative, yet served in a casual setting.",
    cards = combos
}) => {

    return (
        <Container>
            <ContentWithPadding2Xl>
                <HeaderRow>
                    <Header>{heading}</Header>
                </HeaderRow>
                <Description>{description}</Description>
                <TabContent
                    variants={{
                        current: {
                            opacity: 1,
                            scale: 1,
                            display: "flex",
                        },
                        hidden: {
                            opacity: 0,
                            scale: 0.8,
                            display: "none",
                        }
                    }}
                    transition={{ duration: 0.4 }}
                    initial="current"
                    animate="current"
                >
                    {cards.map((card, index) => (
                        <CardContainer key={index}>
                            <Card className="group" initial="rest" whileHover="hover" animate="rest">
                                <CardImageContainer imageSrc={card.imageSrc} bgSize={card.bgSize} bgPosition={card.bgPosition}>
                                </CardImageContainer>
                                <CardText>
                                    <CardTitle>{card.title}</CardTitle>
                                    {/* <CardRatingContainer>
                                        <CardRating>
                                            <StarIcon />
                                            {card.rating} / 5
                                        </CardRating>
                                        <CardPrice>{card.price}</CardPrice>
                                    </CardRatingContainer> */}
                                    <CardContent>{card.content}</CardContent>
                                </CardText>
                            </Card>
                        </CardContainer>
                    ))}
                </TabContent>
                <ButtonContainer>
                    <LinkButton to="/the-heron-menu">View More</LinkButton>
                </ButtonContainer>
            </ContentWithPadding2Xl>
        </Container>
    );
};

