import { Alert, DatePicker } from "antd";
import { useEffect, useState } from "react";
import tw from "twin.macro";
import { generateClient } from "aws-amplify/api";
import { listClosedDates } from "graphql/queries";
import { OutlineSecondaryButton } from "components/misc/Buttons";
import dayjs from "dayjs";
import { DATE_FORMAT } from "pham-sushi/helpers/constants";
import { createClosedDate, updateClosedDate } from "graphql/mutations";

const client = generateClient();

const DatePickerWrapper = tw.div`flex gap-4 mb-4`;
const SubmitButton = tw(OutlineSecondaryButton)`inline-block`;

const Setting = () => {
  const [savedDates, setSavedDates] = useState();
  const [dates, setDates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    let isUnmounted = false;
    // List all items
    try {
      const getData = async () => {
        setIsLoading(true);
        const allClosedDates = await client.graphql({
          query: listClosedDates,
        });
        const data = allClosedDates?.data?.listClosedDates?.items?.[0];
        if (!isUnmounted) {
          setSavedDates(data);
          setDates(data && data.date ? data.date.split(",") : []);
          setIsLoading(false);
        }
      };
      getData();
    } catch (error) {
      if (!isUnmounted) {
        setIsLoading(false);
      }
    }
    return () => {
      isUnmounted = true;
    };
  }, []);

  const onChange = (_, dateString) => {
    setIsSaved(false);
    setError("");
    setDates(dateString.filter((d) => !!d));
  };

  const handleSave = async () => {
    let isUnmounted = false;
    if (isLoading) return;

    try {
      setIsLoading(true);
      await client.graphql({
        query: savedDates?.id ? updateClosedDate : createClosedDate,
        variables: {
          input: {
            id: savedDates?.id,
            date: dates.filter((d) => !!d).join(),
          },
        },
        authMode: "userPool",
      });
      if (!isUnmounted) {
        setIsLoading(false);
        setIsSaved(true);
        setError("");
      }
    } catch (error) {
      if (!isUnmounted) {
        setIsLoading(false);
        setIsSaved(true);
        setError(error);
      }
    }

    return () => {
      isUnmounted = true;
    };
  };

  return (
    <>
      <DatePickerWrapper>
        <DatePicker
          multiple
          onChange={onChange}
          maxTagCount="responsive"
          // defaultValue={defaultValue}
          format={DATE_FORMAT}
          size="large"
          placeholder={DATE_FORMAT}
          value={dates
            .filter((d) => !!d)
            .map((date) => dayjs(date, DATE_FORMAT))}
        />
        <SubmitButton type="submit" disabled={isLoading} onClick={handleSave}>
          Save
        </SubmitButton>
      </DatePickerWrapper>
      {isSaved &&
        (error ? (
          <Alert message="Saved Failure" type="error" showIcon />
        ) : (
          <Alert message="Saved Successfully!" type="success" showIcon />
        ))}
    </>
  );
};
export default Setting;
