import { withAuthenticator } from "@aws-amplify/ui-react";
import tw from "twin.macro";
import AppTabs from "pham-sushi/components/AppTabs";
import Announcements from "./Announcements";
import ClosedDates from "./ClosedDates";


const Container = tw.div`m-4 sm:m-16`;

const Setting = () => {
  const tabs = {
    Announcements: {
      render: () => <Announcements />,
    },
    "Closed Dates": {
      render: () => <ClosedDates />,
    },
  };

  return (
    <Container>
      <AppTabs tabs={tabs} />
    </Container>
  );
};
export default withAuthenticator(Setting, { hideSignUp: true });
