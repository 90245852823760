// import "antd/dist/antd.min.css";
import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import "@aws-amplify/ui-react/styles.css"
import React from "react";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import backgroundImageSrc from "images/sushi/bg-layer-0.png";
import topLeftCornerSrc from "images/sushi/top-left-corner.png";
/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

/* Use AnimationRevealPage as a wrapper component for your pages if you are building a custom one yourself */
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";

/*
 * Hero section is the top most section on the page. It contains the header as well.
 * So you dont need to import headers
 * separately
 */

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SushiLanding from 'pham-sushi/pages/Landing.js';
import TheHeronMenu from "pham-sushi/pages/TheHeronMenu";
import TakeawayMenu from "pham-sushi/pages/TakeawayMenu";
import Header from "pham-sushi/components/Header";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Footer from "pham-sushi/components/Footer";
import ContactUs from "pham-sushi/pages/ContactUs";
import Reservation from "pham-sushi/pages/Reservation";
import SimpleContactUs from "components/forms/SimpleContactUs";
import ScrollToTop from "pham-sushi/components/ScrollToTop";
import { Amplify } from 'aws-amplify';
import config from './amplifyconfiguration.json';
import Setting from "pham-sushi/pages/Setting";
Amplify.configure(config);

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;
  const Page = styled.div`
  ${css`
    background-color: #000;
    position: relative;
    z-index: 0;
    &::before {
        content: '';
        background-image: url(${backgroundImageSrc});
        background-position: left top;
        background-repeat: repeat;
        background-size: 100% auto;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    &::after {
        content: '';
        background-image: url(${topLeftCornerSrc});
        background-position: left top;
        background-repeat: no-repeat;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
  `}
`;

  return (
    <Router>
      <Page>
        <AnimationRevealPage>
          <Header />
          <ScrollToTop />
          <Switch>
            <Route path="/main" exact>
              <SimpleContactUs />
            </Route>
            <Route path="/" exact>
              <SushiLanding />
            </Route>
            <Route path="/the-heron-menu">
              <TheHeronMenu />
            </Route>
            <Route path="/takeaway-menu">
              <TakeawayMenu />
            </Route>
            <Route path="/contact-us">
              <ContactUs />
            </Route>
            <Route path="/reservation">
              <Reservation />
            </Route>
            <Route path="/reservation/:id">
              <Reservation />
            </Route>
            <Route path="/setting">
              <Setting />
            </Route>
            <Route path="*">
              <SushiLanding />
            </Route>
          </Switch>
          <Footer />
        </AnimationRevealPage>
      </Page>
    </Router>
  );
}
