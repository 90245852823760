import React from "react";
import tw from "twin.macro";
import InputForm from "pham-sushi/components/TwoColWithForm";
import { sendMail } from "pham-sushi/services/mail.service";
import { CONTACT_US_EMAIL_TEMP } from "pham-sushi/helpers/constants";

export default () => {
  const formCss = tw`max-w-38r md:mt-20 md:mb-12`;
  const imageCss = tw`lg:ml-35 lg:mt-12`;
  const fields = [
    {
      type: "email",
      name: "email",
      placeholder: "Your Email Address",
      required: true,
    },
    {
      type: "text",
      name: "name",
      placeholder: "Full Name",
      required: true,
    },
    {
      type: "text",
      name: "subject",
      placeholder: "Subject",
      required: true,
    },
    {
      type: "textarea",
      name: "message",
      placeholder: "Your Message Here",
      required: true,
    },
  ];

  const handleSubmit = async (value) => {
    await sendMail(value, CONTACT_US_EMAIL_TEMP);
    return true;
  };
  
  return (
    <>
      <InputForm
        fields={fields}
        onSubmit={handleSubmit}
        formCss={formCss}
        imageCss={imageCss}
      />
    </>
  );
};
