import React from "react";
import TabCardGrid from "pham-sushi/components/TabCardGrid.js";
import { menuTheHeron } from "pham-sushi/data/menu";


export default () => {
    return (
        <>
            {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
            <TabCardGrid
                heading={
                    <>
                        The Heron Menu
                    </>
                } 
                tabs={menuTheHeron}
            />
        </>
    );
}
