export const locations = [
    {
        id: 1,
        active: true,
        name: 'The Heron | Takeaway',
        address: 'The Heron, 5 Moor Lane, London',
        code: 'EC2Y 9AP',
        gmapsLink: 'https://goo.gl/maps/jKJVhxy28ZbgdF9RA',
        tel: '020 7638 6668 | 020 7253 8658',
        telGlobal: '+442076386668|+442072538658',
        email: 'theheron@phamsushi.com | phamsushi@aol.com',
        imgSrc: 'ldn-ec2y-9ap.jpg'
    }
];