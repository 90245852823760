import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { LinkButton } from "components/misc/Buttons.js";
import { SectionHeading } from "components/misc/Headings";
import headerBgImage from "images/sushi/header-bg.png";

const GridTitle = styled(SectionHeading)`
    ${tw`flex mt-20 relative`}
    align-items: center;
    text-align: center;
    &::before,
    &::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #FFF;
    }
    &:not(:empty)::before {
        margin-right: .25em;
    }
    &:not(:empty)::after {
        margin-left: .25em;
    }
`;
const GridTitleInner = styled.div`
    background-image: url(${headerBgImage});
    background-position: center top;
    background-repeat: no-repeat;
    background-size: auto 100%;
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 246%;
    top: -90%;
`;
const GridContainer = tw.div``;
const GridContainerInner = tw.div`flex flex-wrap`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.div)`rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 bg-center bg-no-repeat relative rounded-t`}
`;
const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(0, 0, 0, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(LinkButton)`text-sm`;
const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold text-white group-hover:text-gray-100`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-500`;
const CardPrice = tw.p`mt-4 text-xl font-bold text-gray-600`;


export default ({
    data = {},
    showPrice = false
}) => {
    /*
     * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
     * as the key and value of the key will be its content (as an array of objects).
     * To see what attributes are configurable of each object inside this array see the example above for "Starters".
     */
    const dataKeys = Object.keys(data);

    return (
        <>
            {dataKeys && dataKeys.length > 0 && dataKeys.map((subMenuKey) => (
                data[subMenuKey] && data[subMenuKey].length > 0 &&
                <GridContainer key={subMenuKey}>
                    {subMenuKey !== '_default' &&
                        <GridTitle>
                            {subMenuKey}
                            {/* <GridTitleInner /> */}
                        </GridTitle>
                    }
                    <GridContainerInner>
                        {data[subMenuKey].map((card, index) => (
                            <CardContainer key={index}>
                                <Card className="group" initial="rest" whileHover="hover" animate="rest">
                                    <CardImageContainer imageSrc={card.imageSrc}>
                                        {/* <CardRatingContainer>
                                            <CardRating>
                                            <StarIcon />
                                            {card.rating}
                                            </CardRating>
                                            <CardReview>({card.reviews})</CardReview>
                                        </CardRatingContainer> */}
                                        <CardHoverOverlay
                                            variants={{
                                                hover: {
                                                    opacity: 1,
                                                    height: "auto"
                                                },
                                                rest: {
                                                    opacity: 0,
                                                    height: 0
                                                }
                                            }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <CardButton to={card.url}>Make Reservation</CardButton>
                                        </CardHoverOverlay>
                                    </CardImageContainer>
                                    <CardText>
                                        <CardTitle>{card.title}</CardTitle>
                                        <CardContent>{card.content}</CardContent>
                                        { showPrice && <CardPrice>{card.price}</CardPrice> }
                                    </CardText>
                                </Card>
                            </CardContainer>
                        ))}
                    </GridContainerInner>
                </GridContainer>
            ))}
        </>
    );
};

