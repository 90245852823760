import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  OutlineSecondaryButton,
  OutlinePrimaryButton,
} from "components/misc/Buttons.js";
import dayjs from "dayjs";
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DISABLED_HOURS,
} from "pham-sushi/helpers/constants";
import {
  DatePicker,
  Select,
  Form as AntForm,
  Input as AntInput,
  Alert,
} from "antd";
import { range } from "pham-sushi/helpers/utils";
const { Option } = Select;
const { Item } = AntForm;

const Form = tw(AntForm)`mt-8 text-sm flex flex-col mx-auto md:mx-0`;
const FormControl = styled(Item)`
  ${tw`w-full mt-8 first:mt-0`}
  input, textarea, .ant-picker {
    ${tw`w-full`}
    ::placeholder {
      ${tw`text-gray-300`}
    }
  }
`;
const Input = styled(AntInput)`
  ${tw`py-07r px-0 bg-transparent! text-gray-500 text-base lg:text-xl rounded-none border-t-0! border-l-0! border-r-0! border-b-05 border-gray-400! hocus:border-gray-lightest py-07r focus:outline-none font-medium transition duration-300 hocus:border-gray-lightest!`}
  ${css`
    box-shadow: none !important;
  `}
`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-32`}
`;
const SingleSelect = styled(Select)`
  ${tw`h-[54px] py-07r focus:outline-none font-medium transition duration-300 border-t-0! border-l-0! border-r-0! border-b-05 border-gray-400 hocus:border-gray-lightest`}
  &.ant-select-focused {
    .ant-select-selector {
      box-shadow: none !important;
    }
  }
  .ant-select-selector {
    ${tw`bg-transparent! pl-0! border-0! hocus:border-0! text-left`}
    .ant-select-selection-search {
      input {
        line-height: 2rem;
      }
    }
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      ${tw`px-0! text-base lg:text-xl`}
    }
    .ant-select-selection-item {
      ${tw`text-gray-500`}
    }
    .ant-select-selection-placeholder {
      ${tw`text-gray-300`}
    }
  }
  .ant-select-arrow {
    ${tw`px-0! text-gray-400 right-0`}
  }
`;
const DateTimePicker = styled(DatePicker)`
  ${tw`h-[54px] bg-transparent! p-0 focus:outline-none font-medium transition duration-300 rounded-none border-t-0! border-l-0! border-r-0! border-b-05 border-gray-400! hocus:border-gray-lightest!`}
  &.ant-picker-focused {
    box-shadow: none;
  }
  input,
  .ant-picker-suffix .anticon {
    ${tw`text-base lg:text-xl text-gray-400`}
  }
  .ant-picker-input > input {
    ${tw`text-gray-500 text-base lg:text-xl`}
    &::placeholder {
      ${tw`text-gray-300`}
    }
  }
  .ant-select-selection-search {
    ${tw`hidden`}
  }
  .ant-select-selection-item {
    ${tw`py-07r`}
  }
`;
const ButtonWrapper = tw.div`mt-14 flex gap-4`;
const SubmitButton = tw(OutlineSecondaryButton)`inline-block`;
const CancelButton = tw(OutlinePrimaryButton)`inline-block`;

export default ({
  fields = [
    {
      type: "email",
      name: "email",
      placeholder: "Your Email Address",
    },
    {
      type: "text",
      name: "name",
      placeholder: "Full Name",
    },
    {
      type: "text",
      name: "subject",
      placeholder: "Subject",
    },
    {
      type: "textarea",
      name: "message",
      placeholder: "Your Message Here",
    },
  ],
  onSubmit = (value) => {
    console.log("form submit", value);
  },
  submitButtonText = "Submit",
  formCss,
  onCancel,
  cancelButtonText,
  values,
  errorMessage = "Submitted Failure",
  successMessage = "Submitted Successfully!",
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (values) {
      form.setFieldsValue(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  const handleChange = (name, value) => {
    setIsSaved(false);
    setError("");
  };

  const disabledDate = (
    current,
    holidays = [
      "2024-03-29",
      "2024-03-30",
      "2024-03-31",
      "2024-04-01",
      "2024-05-06",
      "2024-05-27",
      "2024-08-26",
      "2024-12-25",
      "2024-12-26",
      "2025-01-01",
      "2025-04-18",
      "2025-04-21",
      "2025-05-05",
      "2025-05-26",
      "2025-08-25",
      "2025-12-25",
      "2025-12-26",
    ]
  ) => {
    // Can not select days before today and today
    // or Sunday
    // or pre-defined public holidays
    return (
      dayjs(current).day() === 0 ||
      holidays.includes(dayjs(current).format(DATE_FORMAT))
    );
  };
  const disabledDateTime = (current, specialDates = []) => ({
    disabledHours: () => {
      let hours = [];
      hours.push(...DISABLED_HOURS);
      if (
        dayjs(current).day() === 6 ||
        specialDates.includes(dayjs(current).format(DATE_FORMAT))
      ) {
        hours.push(...[12, 13, 14, 15]);
      }

      return hours;
    },
    disabledMinutes: (hour) => {
      let minutes = [];
      if (hour === 15 || hour === 22) {
        minutes = minutes.concat(range(1, 60));
      }
      if (dayjs(current).day() === 6) {
        if (hour === 15) {
          minutes = minutes.concat([0]);
        }
        if (hour === 17) {
          minutes = minutes.concat(range(0, 30));
        }
      }

      return minutes;
    },
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const isReset = await onSubmit?.(values);
      if (isReset) {
        form.resetFields();
      }
      setIsSaved(true);
      setError("");
    } catch (error) {
      setIsSaved(true);
      setError(error);
    }
    setLoading(false);
  };
  const handleCancel = () => {
    form.resetFields();
    onCancel?.();
  };
  const renderField = (field) => {
    switch (field.type) {
      case "email":
      case "text":
        return (
          <Input
            onChange={(event) => handleChange(field.name, event.target.value)}
            type={field.type}
            placeholder={field.placeholder}
          />
        );
      case "textarea":
        return (
          <Textarea
            onChange={(event) => handleChange(field.name, event.target.value)}
            placeholder={field.placeholder}
          />
        );
      case "select":
        return (
          <SingleSelect
            onChange={(value) => handleChange(field.name, value)}
            placeholder={field.placeholder}
            filterOption={false}
          >
            {field.options.map((obj) => (
              <Option key={obj}>{obj}</Option>
            ))}
          </SingleSelect>
        );
      case "datetime":
        return (
          <DateTimePicker
            showTime={{
              format: "HH:mm",
              defaultValue: dayjs("12:30:00", "HH:mm:ss"),
            }}
            showNow={false}
            format={DATE_TIME_FORMAT}
            onChange={(value, dateString) =>
              handleChange(field.name, dateString)
            }
            minDate={dayjs()}
            disabledDate={(current) => disabledDate(current, field.holidays)}
            disabledTime={(current) =>
              disabledDateTime(current, field.specialDates)
            }
            inputReadOnly={true}
            allowClear={false}
            placeholder={field.placeholder}
            disabled={field.disabled}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Form form={form} onFinish={handleSubmit} autoComplete="off" css={formCss}>
      {fields &&
        fields.map((field) => (
          <FormControl
            key={field.name}
            name={field.name}
            rules={[
              { required: field.required, message: "This field is required" },
            ]}
          >
            {renderField(field)}
          </FormControl>
        ))}
      {isSaved &&
        (error ? (
          <Alert message={errorMessage} type="error" showIcon />
        ) : (
          <Alert message={successMessage} type="success" showIcon />
        ))}
      <ButtonWrapper>
        {cancelButtonText && (
          <CancelButton type="button" onClick={handleCancel}>
            {cancelButtonText}
          </CancelButton>
        )}
        <SubmitButton type="submit" disabled={loading}>
          {submitButtonText}
        </SubmitButton>
      </ButtonWrapper>
    </Form>
  );
};
