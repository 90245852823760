
export const testimonials = [
    {
        id: 1,
        summary: 'Top value for money',
        quote:
            `The best sushi place I have ever been. It’s a very executive place that it is not that well known outside of that. You can eat really well for a very fair amount of money. Loved the food and also loved how friendly the staff was. I will definitely be back`,
        customerName: "Juliannn21\nLancashire, United Kingdom"
    },
    {
        id: 2,
        summary: 'Good food and very helpful staff',
        quote:
            `We were going to the Barbican and doe to traffic we were latt getting to the restaurant. The staff were very helpful and although we only had time for sashimi and sushi we found the dishes were delicious. We would definitely go again.`,
        customerName: "Heatherp372"
    },
    {
        id: 3,
        summary: 'Favourite sushi place',
        quote:
            `We love Pham Sushi. Have been visiting for years for various occasions, often visit with clients, with friends and for the last two years have even been for Valentine's Day meal with my partner. Our most recent visit was Valentine's Day this year. My online booking for some reason did not appear in their system but their lovely staff accommodated us regardless even though it was a busy night! The waiters are all very polite, helpful and attentive, I know a few have been there for a long time now, which is great to see. We had the rock shrimp tempura to start which is one of our favourite dishes, it never disappoints. We had an array of sushi rolls after this, all so fresh, absolutely delicious and beautifully presented. We had a fantastic meal, of course, we over ordered so we had some to take home too, which is never a bad thing! Overall the cost was not bad for what we had. Another great meal, thank you!!`,
        customerName: "DUCKIE0816\nLondon, United Kingdom"
    },
    {
        id: 4,
        summary: 'Ideal pre-theatre meal',
        quote:
            `We found this restaurant on Tripadviser when looking for somewhere near the Barbican Centre for a pre-theatre meal. A few minutes walk from Moorgate station. We chose the pre-theatre set menu at £25 each. Miso soup and endame to start followed by a large plate of very tasty shashimi and a sushi selection plus a glass of wine. We also shared some ice cream (green tea plus sesame). All was beautifully presented and tasted very good. Service excellent . Would recommend.`,
        customerName: "HB2981\nNewcastle Upon Tyne, United Kingdom"
    },
    {
        id: 5,
        summary: 'Quick pre-theatre meal',
        quote:
            `Just wanted to get a bite to eat before the theatre (Barbican) and came across this small restaurant. Extremely impressed with the food and service. Between the four of us we had a wide selection of food and all were happy with their meals. Pressure tends to be on when you have to be quick but the service was great. Will be returning.`,
        customerName: "JohnrW6056KI\nLondon, United Kingdom"
    },
    {
        id: 6,
        summary: 'The Japanese real deal!',
        quote:
            `After a late evening Pilates class, the last thing that come to my mind would be sushi!
            That said Pham Sushi is where healthy food meets authentic Japanese food experience. The combinations of the rolls are just superb and an unexpected seaweed salad is a fresh breeze from the ocean. The cocktail I had was called Bonsay Bloom and have to say the cucumber slice with the ice was as good as the Sake on that mix.
            Thoroughly enjoyed albeit a tad expensive but we did order also a Teriyaki Tuna steak with soya beans and a few cocktails so can't complain.
            A fresh, subtle and delicate approach to food for all the Sushi lovers.`,
        customerName: "Luismoreira2000\nLondon, United Kingdom"
    },
    {
        id: 7,
        summary: 'Fresh and delicious sushi!',
        quote:
            `Gorgeously presentes sushi which tasted so fresh and healthy. Would thoroughly recommend the crunchy tuna roll and flying dragon roll - some of the best sushi I’ve tasted!`,
        customerName: "174lazd"
    },
    {
        id: 8,
        summary: 'Great Sushi and Sashimi',
        quote:
            `My friend and I dined here tonight. The food was great, We had the 9 piece sashimi set, three different sushi rolls, vegetable tempura and the soup that comes in the little teapots (with prawn, chicken, fish cake and mushroom in it,)
            Everything was faultless and the service efficient. We had a bottle of sparkling water between us. Came to £40 each which was decent as we were both full and had had some lovely food.`,
        customerName: "jillyinlondon"
    },
    {
        id: 9,
        summary: 'Fresh and diverse sushi options',
        quote:
            `The fish is fresh and besides tasty salmon and tuna, the restaurant has a wide range of white fishes. The hot dishes are also very delicious and a good choice for lunch (salmon bento)`,
        customerName: "142arianep"
    }
];