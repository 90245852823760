import phamSpecialsImage from "images/sushi/pham-specials.png";
import specialSashimiImage from "images/sushi/special-sashimi.png";
import eveningSpecialsImage from "images/sushi/evening-specials.png";
import sushiSashimiImage from "images/sushi/sushi-sashimi.png";

export const combos = [
    {
        imageSrc: phamSpecialsImage,
        title: "Eel Nigiri",
        content: "Smoked eel on a loaf of a sushi rice",
        price: "$5.99",
        rating: "5.0",
        reviews: "87",
        url: '/reservation',
        bgSize: '120% auto',
        bgPosition: 'left -3rem top 1.5rem'
    },
    {
        imageSrc: specialSashimiImage,
        title: "Prawn Nigiri",
        content: "Fully cooked Prawn on a loaf of a sushi rice",
        price: "$2.99",
        rating: "4.8",
        reviews: "32",
        url: '/reservation',
        bgSize: '120% auto',
        bgPosition: 'left -3rem top 0.5rem'
    },
    {
        imageSrc: eveningSpecialsImage,
        title: "Assorted Sashimi",
        content: "Selection of different kind of fish, chef's choice",
        price: "$7.99",
        rating: "4.9",
        reviews: "89",
        url: '/reservation',
        bgSize: '120% auto',
        bgPosition: 'left -3.5rem top -1rem'
    },
    {
        imageSrc: sushiSashimiImage,
        title: "Sushi Roll",
        content: "Hand made Sushi Roll using different ingredients",
        price: "$8.99",
        rating: "4.6",
        reviews: "12",
        url: '/reservation',
        bgSize: '100% auto',
        bgPosition: 'left -1.5rem top -1.5rem'
    }
];