import React from "react";
import styled from "styled-components";
import { css } from "styled-components/macro";
import tw from "twin.macro";
import { Link } from "react-router-dom";
import LogoImage from "images/sushi/logo.png";

const Container = tw.div`relative text-white bg-black-200 pt-16`;
const Content = tw.div`max-w-screen-3xl mx-5 3xl:mx-auto pb-9`
const FiveColumns = tw.div`grid lg:grid-cols-5 md:grid-cols-1`;
const Column = tw.div`lg:w-1/2 md:w-full mb-8 lg:pl-12 xl:pl-20 3xl:pl-42 text-sm sm:text-base text-center lg:w-auto lg:col-span-1 md:col-span-1`;
const CompanyColumn = tw.div`text-center md:text-left mb-16 lg:mb-0 w-full lg:w-3/5 lg:col-span-3 md:col-span-1`;
const ColumnHeading = tw.h5`font-bold text-white uppercase`;
const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const LinkItem = tw(Link)`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;
const LogoContainer = tw.div`flex items-center justify-center lg:justify-start`;
const LogoImg = styled.img`
  ${css`
    image-rendering: auto;
    image-rendering: crisp-edges;
    image-rendering: pixelated;
    image-rendering: -webkit-optimize-contrast;`
  }
  ${tw`w-48`}
`;
const CompanyAddress = tw.p`mt-6 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left`;
const CopyrightAndCompanyInfoRow = tw.div`mt-21 pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-center justify-center lg:justify-start`;
const CopyrightNotice = tw.div``;

export default () => {
  return (
    <Container>
      <Content>
        <FiveColumns>
          <CompanyColumn>
            <LogoContainer>
              <Link to="/">
                <LogoImg src={LogoImage} />
              </Link>
            </LogoContainer>
            <CompanyAddress>
              The Heron | Takeaway: 5 Moor Lane, EC2Y 9AP
            </CompanyAddress>
          </CompanyColumn>
          <Column>
            <ColumnHeading>Menu</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <LinkItem to="/the-heron-menu">The Heron Menu</LinkItem>
              </LinkListItem>
              <LinkListItem>
                <LinkItem to="/takeaway-menu">Takeaway Menu</LinkItem>
              </LinkListItem>
              <LinkListItem>
                <LinkItem to={{ pathname: "https://deliveroo.co.uk/menu/London/old-street/pham-sushi-ltd/?day=today&geohash=gcpvjfyqkw2w&time=ASAP" }} target="_blank" rel="noopener noreferrer">Order by Deliveroo</LinkItem>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Contact</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <LinkItem to="/contact-us">Contact us</LinkItem>
              </LinkListItem>
              <LinkListItem>
                <LinkItem to="/reservation">Make Reservation</LinkItem>
              </LinkListItem>
            </LinkList>
          </Column>
        </FiveColumns>
        <CopyrightAndCompanyInfoRow>
          <CopyrightNotice>&copy; Copyright 2022-2024, Pham Sushi.</CopyrightNotice>
        </CopyrightAndCompanyInfoRow>
      </Content>
    </Container>
  );
};
