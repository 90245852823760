import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ContentWithPaddingXl, Container } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import headerBgImage from "images/sushi/header-bg.png";
import { SlickSlider } from "pham-sushi/misc/slider";
import { getTestimonials } from "pham-sushi/services/testimonial.service";

const ContentWithPadding = tw(ContentWithPaddingXl)`py-16 lg:py-20`;
const HeadingContainer = tw.div`text-center mb-8`;
const Heading = tw(SectionHeading)`text-white font-bold my-4`;
const Subheading = styled(SubheadingBase)`
  ${tw`mx-auto relative inline-block text-white font-normal text-xl sm:text-2xl`}
  ${css`
      &::before {
          background-image: url(${headerBgImage});
          background-position: left top;
          background-repeat: no-repeat;
          background-size: auto 100%;
          content: '';
          position: absolute;
          z-index: -1;
          width: 200%;
          height: 500%;
          top: 0;
          left: 0;
          transform: translate(-15%, -40%);
      }
  `}
`;
const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row mb-16`;
const Header = styled(SectionHeading)`
    ${tw`mx-auto relative font-bold text-gray-600`}
    ${css`
        &::before {
            background-image: url(${headerBgImage});
            background-position: left top;
            background-repeat: no-repeat;
            background-size: auto 100%;
            content: '';
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 246%;
            top: -90%;
            left: 30%;
        }
    `}
`;
const Testimonials = styled(SlickSlider)`
  .slick-dots {
    bottom: -4rem;
  }
`;
const TestimonialContainer = tw.div`lg:w-1/3`;
const Testimonial = tw.div`px-4 pb-16 text-center max-w-25r mx-auto flex flex-col items-center h-full relative min-h-60`;
const Summary = tw.div`text-white font-extrabold`;
const Quote = styled.blockquote`
  ${tw`mt-4 text-white font-medium`}
  // ${css`
  //   display: -webkit-box;
  //   -webkit-line-clamp: 3;
  //   -webkit-box-orient: vertical;  
  //   overflow: hidden;
  //   &:hover {
  //     -webkit-line-clamp: unset;
  //   }
  // `}
`;
const ToggleFullQuote = styled.i`
  ${css`
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  `}
`;
const CustomerName = tw.p`mt-5 text-gray-400 font-semibold uppercase text-sm tracking-wide absolute bottom-0 whitespace-pre-line`;

export default ({
  subheading = "",
  heading = "Testimonials",
  testimonials = getTestimonials()
}) => {
  const [showFullQuotes, setShowFullQuotes] = useState([]);
  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    initialSlide: 0,
    nextArrow: <></>,
    prevArrow: <></>,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    afterChange: () => setShowFullQuotes([])
  };
  const handleToggleFullQuote = (id) => {
    const idx = showFullQuotes.findIndex(item => item === id);
    if (idx < 0) {
      setShowFullQuotes(prevState => [...prevState, id]);
    } else {
      setShowFullQuotes(prevState => prevState.filter(item => item !== id));
    }
  };
  return (
    <Container>
      <ContentWithPadding>
        <HeaderRow>
          <Header>{heading}</Header>
        </HeaderRow>
        <Testimonials {...settings}>
          {testimonials.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <Testimonial>
                <Summary>{testimonial.summary}</Summary>
                {testimonial.shortQuote &&
                  (showFullQuotes.findIndex(item => item === testimonial.id) < 0 ?
                    <Quote>"{testimonial.shortQuote}" <br/><ToggleFullQuote onClick={() => handleToggleFullQuote(testimonial.id)}>show more</ToggleFullQuote></Quote>
                    : <Quote>"{testimonial.quote}" <br/><ToggleFullQuote onClick={() => handleToggleFullQuote(testimonial.id)}>show less</ToggleFullQuote></Quote>
                  )
                }
                {!testimonial.shortQuote &&
                  <Quote>"{testimonial.quote}"</Quote>
                }
                <CustomerName>- {testimonial.customerName}</CustomerName>
              </Testimonial>
            </TestimonialContainer>
          ))}
        </Testimonials>
      </ContentWithPadding>
    </Container>
  );
};
