import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Link } from "react-router-dom";
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";
import logo from "images/sushi/logo.png";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-3xl mx-5 3xl:mx-auto my-5 md:my-8
`;
export const NavLinks = tw.div`inline-block`;
/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw(Link)`
  text-lg text-black-100 lg:text-gray-600 mr-0 lg:mr-18 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-3 lg:pb-1 border-b-2 border-transparent lg:hover:border-gray-600 lg:hocus:text-gray-600
`;
export const ButtonLink = tw(NavLink)`
  text-base lg:mx-0
  p-3 rounded bg-black-100
  border border-gray-600
  inline-block text-center
  min-w-btn
  text-gray-600
  lg:hover:bg-transparent
  hocus:text-white hocus:border-white
`;
export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`mr-3`}
  }
`;
export const MobileNavLinksContainer = tw.nav`relative flex flex-1 items-center justify-between`;
export const NavToggle = styled.button`
  ${tw`lg:hidden z-20 focus:outline-none text-gray-600 hocus:text-white transition duration-300`}
  &.open{
    ${tw`text-black-100 absolute`}
    ${css`top: 10px; right: 10px`}
  }
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 m-6 p-8 border text-center rounded-lg text-black-100 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);
export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;
export default ({ roundedHeaderButton = false, logoLink, links, className, collapseBreakpointClass = "lg" }) => {
  /*
   * This header component accepts an optionals "links" prop that specifies the links to render in the navbar.
   * This links props should be an array of "NavLinks" components which is exported from this file.
   * Each "NavLinks" component can contain any amount of "NavLink" component, also exported from this file.
   * This allows this Header to be multi column.
   * So If you pass only a single item in the array with only one NavLinks component as root, you will get 2 column header.
   * Left part will be LogoLink, and the right part will be the the NavLinks component you
   * supplied.
   * Similarly if you pass 2 items in the links array, then you will get 3 columns, the left will be "LogoLink", the center will be the first "NavLinks" component in the array and the right will be the second "NavLinks" component in the links array.
   * You can also choose to directly modify the links here by not passing any links from the parent component and
   * changing the defaultLinks variable below below.
   * If you manipulate links here, all the styling on the links is already done for you. If you pass links yourself though, you are responsible for styling the links or use the helper styled components that are defined here (NavLink)
   */

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLinks = [
    <NavLinks key={1}>
      <NavLink onClick={toggleNavbar} to="/the-heron-menu">The Heron Menu</NavLink>
      <NavLink onClick={toggleNavbar} to="/takeaway-menu">Takeaway Menu</NavLink>
      <NavLink to={{ pathname: "https://deliveroo.co.uk/menu/London/old-street/pham-sushi-ltd/?day=today&geohash=gcpvjfyqkw2w&time=ASAP" }} target="_blank" rel="noopener noreferrer">Order by Deliveroo</NavLink>
      <NavLink onClick={toggleNavbar} to="/contact-us">Contact Us</NavLink>
      <ButtonLink onClick={toggleNavbar} css={roundedHeaderButton && tw`rounded-full`} to="/reservation">Make Reservation</ButtonLink>
    </NavLinks>
  ];

  const defaultLogoLink = (
    <LogoLink to="/">
      <img src={logo} alt="logo" />
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
        {logoLink}
        <MobileNavLinks initial={{ x: "150%", display: "none" }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
          {links}
        </MobileNavLinks>
        <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"}>
          {showNavLinks ? <CloseIcon tw="w-6 h-6" /> : <MenuIcon tw="w-6 h-6" />}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
};
