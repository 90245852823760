import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import Hero from "./../components/Hero.js";
import WeAre from "./../components/WeAre.js";
import Combo from "./../components/Combo.js";
import CustomerReview from "./../components/CustomerReview.js";
import BranchLocation from "./../components/BranchLocation.js";
import phamImage from "images/sushi/pham.png";
import theHeronImage from "images/rps-landing-2.jpg";
import { generateClient } from "aws-amplify/api";
import { listAnnouncements } from "graphql/queries.js";
import styled from "styled-components";
import ReactModalAdapter from "helpers/ReactModalAdapter.js";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

const client = generateClient();
const Subheading = tw.span`tracking-wider text-2xl lg:text-4xl font-bold`;
const customerImageCss = tw`w-20! h-20!`;
const StyledModal = styled(ReactModalAdapter)`
  &.styledModal__overlay {
    ${tw`flex items-center justify-center fixed inset-0 z-50`}
  }
  &.styledModal__content {
    ${tw`relative p-8 max-w-screen-sm flex flex-col rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full text-lg text-white whitespace-pre-line`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-4 mr-4 hocus:text-white`;

export default () => {
  const [announcement, setAnnouncement] = useState();

  const closeModal = () => {
    localStorage.setItem("showedAnnouncement", announcement?.id);
    setAnnouncement();
  };

  useEffect(() => {
    // List all items
    const getData = async () => {
      const allAnnouncements = await client.graphql({
        query: listAnnouncements,
      });
      const announcements =
        allAnnouncements?.data?.listAnnouncements?.items || [];
      const sortedByCreatedAt = announcements.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      const showedId = localStorage.getItem("showedAnnouncement");
      if (sortedByCreatedAt?.[0]?.id && sortedByCreatedAt[0].id !== showedId) {
        setAnnouncement(sortedByCreatedAt[0]);
      }
    };
    getData();
  }, []);

  return (
    <>
      <Hero
        heading={
          <>
            Hand Made Sushi
            <br />
            Fresh Fish and Vegetables
          </>
        }
        description={<>We cater for meetings, parties and functions.</>}
        announcement={
          <>
            <br />
          </>
        }
        imageSrc={theHeronImage}
      />
      <WeAre
        subheading={<Subheading></Subheading>}
        heading={<>Pham Sushi</>}
        description={
          <>
            Pham Sushi has been providing authentic Japanese Cuisine for more
            than 20 years.
            <br />
          </>
        }
        textOnLeft={false}
        imageSrc={phamImage}
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <Combo heading={<>Chef Special</>} />
      <CustomerReview
        imageContainerCss={tw`p-2!`}
        imageCss={customerImageCss}
      />
      <BranchLocation />
      {!!announcement && (
        <StyledModal
          closeTimeoutMS={300}
          className="styledModal"
          isOpen={!!announcement}
          onRequestClose={closeModal}
          shouldCloseOnOverlayClick={false}
        >
          <CloseModalButton onClick={closeModal}>
            <CloseIcon tw="w-6 h-6" />
          </CloseModalButton>
          <div className="content">{announcement?.content}</div>
        </StyledModal>
      )}
    </>
  );
};
