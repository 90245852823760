import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";

const ContentWithPadding = tw.div`py-12 lg:py-16 mb-10 lg:mb-64`;
const HeaderRow = tw.div`flex flex-col`;
const TabsControl = tw.div`flex flex-wrap bg-gray-800 px-2 py-2 rounded leading-none`;
const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-600 text-gray-700`}
  }
  ${(props) => props.active && tw`bg-gray-700! text-white!`}
  }
`;
const TabContent = tw(motion.div)`mt-8 max-w-full`;

export default ({ tabs = {} }) => {
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <>
      <HeaderRow>
        {tabsKeys && tabsKeys.length > 0 && (
          <TabsControl>
            {tabsKeys.map((tabName, index) => (
              <TabControl
                key={index}
                active={activeTab === tabName}
                onClick={() => setActiveTab(tabName)}
              >
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
        )}
      </HeaderRow>

      {tabsKeys.map((tabKey, index) => (
        <TabContent
          key={index}
          variants={{
            current: {
              opacity: 1,
              scale: 1,
              display: "block",
            },
            hidden: {
              opacity: 0,
              scale: 0.8,
              display: "none",
            },
          }}
          transition={{ duration: 0.4 }}
          initial={activeTab === tabKey ? "current" : "hidden"}
          animate={activeTab === tabKey ? "current" : "hidden"}
        >
          {tabs[tabKey].render && tabs[tabKey].render()}
        </TabContent>
      ))}
    </>
  );
};
