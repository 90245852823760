import { Link } from "react-router-dom";
import tw from "twin.macro";
export const PrimaryButton = tw.button`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`;
export const OutlinePrimaryButton = tw.button`
    font-bold rounded 
    focus:shadow-outline focus:outline-none transition duration-300
    text-base text-gray-600
    p-3 bg-transparent
    border border-gray-600
    text-center
    inline-block
    min-w-[5rem] sm:min-w-btn
    hover:bg-black-100
    hocus:text-white hocus:border-white
`;
export const OutlineSecondaryButton = tw(OutlinePrimaryButton)`
    bg-black-100
    hover:bg-transparent
`;
export const LinkButton = tw(Link)`
    font-bold rounded 
    focus:shadow-outline focus:outline-none transition duration-300
    text-base text-gray-600
    p-3 bg-transparent
    border border-gray-600
    text-center
    inline-block
    min-w-[5rem] sm:min-w-btn
    hover:bg-black-100
    hocus:text-white hocus:border-white
`;
