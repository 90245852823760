import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPadding2Xl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import headerBgImage from "images/sushi/header-bg.png";
import sushiBarImage from "images/sushi/sushi-bar.png";
import theHeronMapImage from "images/sushi/ldn-ec2y-9ap.jpg";
import { locations as locationsData } from "pham-sushi/data/location";

const ContentWithPadding = styled(ContentWithPadding2Xl)`
    ${tw`pb-0`}
`;
const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = styled(SectionHeading)`
    ${tw`mx-auto relative font-bold text-gray-600`}
    ${css`
        &::before {
            background-image: url(${headerBgImage});
            background-position: left top;
            background-repeat: no-repeat;
            background-size: auto 100%;
            content: '';
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 246%;
            top: -90%;
            left: 30%;
        }
    `}
`;
const MapContent = tw(motion.div)`mt-6 flex flex-col sm:flex-row relative justify-center`;
const ImageContainer = tw.div`inline-block mt-24 lg:w-2/3 md:w-full`;
const TextContainer = styled.div`
    ${tw`relative sm:absolute w-full sm:max-w-90 lg:max-w-50r flex flex-col sm:flex-row mx-auto bottom-0 mb--20p lg:mb--6p rounded-3xl border-2 border-orange bg-white py-4 md:py-8`}
    ${css`
        &::before, &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
        }
        &::before {
            z-index: 1;
            top: -18px;
            left: 80px;
            border-left: 18px solid transparent;
            border-right: 18px solid transparent;
            border-bottom: 18px solid #FF6A49;
        }
        &::after {
            z-index: 2;
            top: -15px;
            left: 80px;
            border-left: 18px solid transparent;
            border-right: 18px solid transparent;
            border-bottom: 18px solid #FFFFFF;
        }
    `}
`;
const TextContent = styled.div`
    ${tw`pt-4 sm:pt-0 border-gray-400! sm:border-r px-4 lg:px-12`}
    ${css`
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            border-right: none;
        }
    `}
`;
const LocationTitle = tw.h5`font-bold mb-5 text-lg md:text-2xl text-gray-200 cursor-pointer`;
const LocationInfo = tw.p`mb-0 text-sm md:text-base text-gray-300 font-medium`;

export default ({
    heading = "Branch Location",
    originLocations = locationsData
}) => {
    const [activeLocation, setActiveLocation] = useState();
    const [locations, setLocations] = useState(originLocations);
    const activateLocation = id => {
        const newLocations = [...locations];
        newLocations.forEach(location => {
            if (location.id === id) {
                location.active = true;
                setActiveLocation(location);
            } else {
                location.active = false;
            }
        }); 
        setLocations(newLocations);
    };

    return (
        <Container>
            <ContentWithPadding>
                <HeaderRow>
                    <Header>{heading}</Header>
                </HeaderRow>
                <MapContent>
                    <ImageContainer>
                        <img css={tw`mx-auto`} src={activeLocation && activeLocation.imgSrc ? require('images/sushi/' + activeLocation.imgSrc).default : theHeronMapImage} alt="" />
                    </ImageContainer>
                    <TextContainer>
                        {locations.map(location => (
                                <TextContent key={location.id}>
                                    <LocationTitle
                                        css={location.active && tw`text-orange`}
                                        onClick={() => activateLocation(location.id)}>
                                        {location.name}
                                    </LocationTitle>
                                    <LocationInfo>
                                        <a href={location.gmapsLink} target="_blank" rel="noopener noreferrer">{location.address}</a>
                                    </LocationInfo>
                                    <LocationInfo>
                                        <a href={location.gmapsLink} target="_blank" rel="noopener noreferrer">{location.code}</a>
                                    </LocationInfo>
                                    <LocationInfo>
                                        Tel: {location.telGlobal.split('|').map((telGlobal, index, array) => (
                                            <React.Fragment key={index}>
                                                <a href={`tel:${telGlobal.trim()}`}>{location.tel.split('|')[index].trim()}</a>
                                                {index < array.length - 1 && ' | '}
                                            </React.Fragment>
                                        ))}
                                    </LocationInfo>
                                    <LocationInfo>
                                        Email: {location.email.split('|').map((email, index, array) => (
                                            <React.Fragment key={index}>
                                                <a href={`mailto:${email.trim()}`}>{email.trim()}</a>
                                                {index < array.length - 1 && ' | '}
                                            </React.Fragment>
                                        ))}
                                    </LocationInfo>
                                </TextContent>
                            ))
                        }
                    </TextContainer>
                </MapContent>
            </ContentWithPadding>
            <img css={tw`w-full mt-40 3xl:mb--18`} src={sushiBarImage} alt="" />
        </Container>
    );
};

