import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { LinkButton } from "components/misc/Buttons.js";

const Container = tw.div`relative mt-20 lg:mt-45`;
const Content = tw.div`lg:absolute w-full h-full`;
const ContentInner = tw.div`mx-5 3xl:mx-auto max-w-screen-3xl text-center lg:text-left`;
const Heading = styled.h1`
  // ${css`font-family: "Cezanne";`}
  ${tw`font-bold text-white text-5xl lg:text-5xl leading-tight`}
`;
const Paragraph = tw.p`mt-4 lg:text-2xl text-gray-400 max-w-lg mx-auto lg:mx-0`;
const Announcement = styled.p`
  ${css`font-family: "Century Gothic"; color: #e4beb3;`}
  ${tw`lg:mt-8 mb-4 lg:text-base text-justify lg:max-w-2xl mx-auto lg:mx-0 whitespace-pre-line`}
`;
const Notes = tw.p`lg:-mt-16 mb-4 lg:text-lg text-gray-400 lg:max-w-2xl mx-auto lg:mx-0 whitespace-pre-line`;
const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start my-4 lg:my-22`;

export default ({
  heading = "Hand Made Sushi<br/>Fresh Fish and Vegetables",
  description = "We cater for meetings, parties and functions.",
  announcement = ``,
  promotionNotes = ``,
  primaryButtonText = "Make Reservation",
  primaryButtonUrl = "/reservation",
  imageSrc
}) => {
  return (
    <>
      <Container>
        <Content>
          <ContentInner>
            <Heading>{heading}</Heading>
            <Paragraph>{description}</Paragraph>
            <Announcement>{announcement}</Announcement>
            <Actions>
              <LinkButton to={primaryButtonUrl}>{primaryButtonText}</LinkButton>
            </Actions>
            <Notes>{promotionNotes}</Notes>
          </ContentInner>
        </Content>
        <img style={{
          marginLeft: 'auto'
        }} src={imageSrc} alt="" />
      </Container>
    </>
  );
};
