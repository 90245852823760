/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAnnouncement = /* GraphQL */ `
  mutation CreateAnnouncement(
    $input: CreateAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    createAnnouncement(input: $input, condition: $condition) {
      id
      content
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAnnouncement = /* GraphQL */ `
  mutation UpdateAnnouncement(
    $input: UpdateAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    updateAnnouncement(input: $input, condition: $condition) {
      id
      content
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAnnouncement = /* GraphQL */ `
  mutation DeleteAnnouncement(
    $input: DeleteAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    deleteAnnouncement(input: $input, condition: $condition) {
      id
      content
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createClosedDate = /* GraphQL */ `
  mutation CreateClosedDate(
    $input: CreateClosedDateInput!
    $condition: ModelClosedDateConditionInput
  ) {
    createClosedDate(input: $input, condition: $condition) {
      id
      date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClosedDate = /* GraphQL */ `
  mutation UpdateClosedDate(
    $input: UpdateClosedDateInput!
    $condition: ModelClosedDateConditionInput
  ) {
    updateClosedDate(input: $input, condition: $condition) {
      id
      date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClosedDate = /* GraphQL */ `
  mutation DeleteClosedDate(
    $input: DeleteClosedDateInput!
    $condition: ModelClosedDateConditionInput
  ) {
    deleteClosedDate(input: $input, condition: $condition) {
      id
      date
      createdAt
      updatedAt
      __typename
    }
  }
`;
