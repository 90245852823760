import menuItemDefaultImg from "images/sushi/logo-only.png";


/* This function is only there for demo purposes. It populates placeholder cards */
export const getRandomCards = () => {
    const cards = [
        {
            imageSrc:
                "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
            title: "Chicken Chilled",
            content: "Chicken Main Course",
            price: "$5.99",
            rating: "5.0",
            reviews: "87",
            url: '/reservation'
        },
        {
            imageSrc:
                "https://images.unsplash.com/photo-1582254465498-6bc70419b607?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
            title: "Samsa Beef",
            content: "Fried Mexican Beef",
            price: "$3.99",
            rating: "4.5",
            reviews: "34",
            url: '/reservation'
        },
        {
            imageSrc:
                "https://images.unsplash.com/photo-1565310022184-f23a884f29da?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
            title: "Carnet Nachos",
            content: "Chilli Crispy Nachos",
            price: "$3.99",
            rating: "3.9",
            reviews: "26",
            url: '/reservation'
        },
        {
            imageSrc:
                "https://images.unsplash.com/photo-1534422298391-e4f8c172dddb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
            title: "Guacamole Mex",
            content: "Mexican Chilli",
            price: "$3.99",
            rating: "4.2",
            reviews: "95",
            url: '/reservation'
        },
        {
            imageSrc:
                "https://images.unsplash.com/photo-1550461716-dbf266b2a8a7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
            title: "Chillie Cake",
            content: "Deepfried Chicken",
            price: "$2.99",
            rating: "5.0",
            reviews: "61",
            url: '/reservation'
        },
        {
            imageSrc:
                "https://images.unsplash.com/photo-1476224203421-9ac39bcb3327??ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
            title: "Nelli",
            content: "Hamburger & Fries",
            price: "$7.99",
            rating: "4.9",
            reviews: "89",
            url: '/reservation'
        },
        {
            imageSrc:
                "https://images.unsplash.com/photo-1455619452474-d2be8b1e70cd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
            title: "Jalapeno Poppers",
            content: "Crispy Soyabeans",
            price: "$8.99",
            rating: "4.6",
            reviews: "12",
            url: '/reservation'
        },
        {
            imageSrc:
                "https://images.unsplash.com/photo-1473093226795-af9932fe5856?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
            title: "Cajun Chicken",
            content: "Roasted Chicken & Egg",
            price: "$7.99",
            rating: "4.2",
            reviews: "19",
            url: '/reservation'
        }
    ];

    // Shuffle array
    return cards.sort(() => Math.random() - 0.5);
};

export const menuTheHeron = {
    'Pham Specials': {
        '_default': [
            {
                uid: 's01',
                imageSrc: menuItemDefaultImg,
                title: "Scallop Creamy",
                content: "Scallop with creamy spicy sauce",
                price: "$5.99",
                rating: "5.0",
                reviews: "87",
                url: '/reservation'
            },
            {
                uid: 's02',
                imageSrc: menuItemDefaultImg,
                title: "Snow Crab",
                content: "Snow crab with mushroom and spicy sauce",
                price: "$2.99",
                rating: "4.8",
                reviews: "32",
                url: '/reservation'
            },
            {
                uid: 's03',
                imageSrc: menuItemDefaultImg,
                title: "Dancing Scallops",
                content: "Pan Fried scallop and mushroom with wasabi and Citrus Sauce",
                price: "$7.99",
                rating: "4.9",
                reviews: "89",
                url: '/reservation'
            },
            {
                uid: 's04',
                imageSrc: menuItemDefaultImg,
                title: "Eel Harumaki",
                content: "Eel Spring rolls with vegetables",
                price: "$8.99",
                rating: "4.6",
                reviews: "12",
                url: '/reservation'
            },
            {
                uid: 's05',
                imageSrc: menuItemDefaultImg,
                title: "Pan Fried Seabream",
                content: "Cooked with Wasabi pepper sauce",
                price: "$7.99",
                rating: "4.2",
                reviews: "19",
                url: '/reservation'
            },
            {
                uid: 's06',
                imageSrc: menuItemDefaultImg,
                title: "Toro Nigiri With Foie Gras",
                content: "Toro with seared Foie Gras",
                price: "$2.99",
                rating: "5.0",
                reviews: "61",
                url: '/reservation'
            },
            {
                uid: 's07',
                imageSrc: menuItemDefaultImg,
                title: "Salmon Tartare",
                content: "Minced Salmon with Wasabi and yuzu sauce",
                price: "$3.99",
                rating: "4.2",
                reviews: "95",
                url: '/reservation'
            },
            {
                uid: 's08',
                imageSrc: menuItemDefaultImg,
                title: "Yellow Tail Tartare",
                content: "Minced Yellow Tail with Wasabi and Yuzu Sauce",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 's09',
                imageSrc: menuItemDefaultImg,
                title: "Tuna Tartare",
                content: "Minced Tuna with Wasabi and Yuzu Sauce",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 's10',
                imageSrc: menuItemDefaultImg,
                title: "Seabass Yuzu sauce",
                content: "Sashimi with Yuzu Dressing",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 's11',
                imageSrc: menuItemDefaultImg,
                title: "Tuna Ceviche",
                content: "Seared Tuna with ceviche sauce",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 's12',
                imageSrc: menuItemDefaultImg,
                title: "Toro Sashimi Mushroom",
                content: "Seared Toro with Mushroom and Jalapeno Sauce",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 's13',
                imageSrc: menuItemDefaultImg,
                title: "Toro Carpaccio",
                content: "Seared Toro with spicy Yuzu Sauce",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 's14',
                imageSrc: menuItemDefaultImg,
                title: "White Tuna Truffle",
                content: "Sashimi with Mushroom and truffle",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 's15',
                imageSrc: menuItemDefaultImg,
                title: "Yellow Tail Jalapeno",
                content: "Sashimi with Jalapeno Sauce",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 's16',
                imageSrc: menuItemDefaultImg,
                title: "Toro with Jalapeno",
                content: "Sashimi with Jalapeno Sauce",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 's17',
                imageSrc: menuItemDefaultImg,
                title: "Salmon New style",
                content: "Seared Salmon with Sake and Soya",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 's18',
                imageSrc: menuItemDefaultImg,
                title: "Gyu Tataki",
                content: "Cold beef with Ponzu Sauce",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 's19',
                imageSrc: menuItemDefaultImg,
                title: "Tuna Tataki",
                content: "Seared Tuna Ponzu Sauce",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 's20',
                imageSrc: menuItemDefaultImg,
                title: "Sea Bass Tiraditor",
                content: "Sashimi with lime and chilli sauce",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ]
    },
    'Sushi & Sashimi': { 
        '_default': [], 
        'Sashimi set': [
            {
                uid: 'ss_ss01',
                imageSrc: menuItemDefaultImg,
                title: "Sashimi 9 slice",
                content: "Chef selection of sashimi",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_ss02',
                imageSrc: menuItemDefaultImg,
                title: "Sashimi 15 slice",
                content: "Chef selection of sashimi",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_ss03',
                imageSrc: menuItemDefaultImg,
                title: "Omakase Sashimi (for 4 people)",
                content: "Chef selection of sashimi",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ], 
        'A la Carte Sashimi': [
            {
                uid: 'ss_alcs01',
                imageSrc: menuItemDefaultImg,
                title: "Salmon Sashimi",
                content: "5 slice",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcs02',
                imageSrc: menuItemDefaultImg,
                title: "Tuna Sashimi",
                content: "5 slice",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcs03',
                imageSrc: menuItemDefaultImg,
                title: "Toro (Fatty Tuna) Sashimi",
                content: "5 slice",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcs04',
                imageSrc: menuItemDefaultImg,
                title: "Seabass Sashimi",
                content: "5 slice",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcs05',
                imageSrc: menuItemDefaultImg,
                title: "Seabream Sashimi",
                content: "5 slice",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcs06',
                imageSrc: menuItemDefaultImg,
                title: "Scallops Sashimi",
                content: "5 slice",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcs07',
                imageSrc: menuItemDefaultImg,
                title: "Turbot Sashimi",
                content: "5 slice",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcs08',
                imageSrc: menuItemDefaultImg,
                title: "White Tuna (Escolar) sashimi",
                content: "5 slice",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcs09',
                imageSrc: menuItemDefaultImg,
                title: "Sweet Shrimp Sashimi",
                content: "5 slice",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ], 
        'Sushi Sets (Chef’s Selection Sushi)': [
            {
                uid: 'ss_sscs01',
                imageSrc: menuItemDefaultImg,
                title: "Tuna and Salmon Sushi Set",
                content: "All Salmon & Tuna",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sscs02',
                imageSrc: menuItemDefaultImg,
                title: "Deluxe Sushi Set",
                content: "8 pcs of Nigiri and 8 pcs of Crunchy Tuna Roll",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sscs03',
                imageSrc: menuItemDefaultImg,
                title: "Sushi & Sashimi Combination Set",
                content: "5 slices of Sashimi, 4pcs of Nigiri and 6 pcs Chef Special cut roll",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sscs04',
                imageSrc: menuItemDefaultImg,
                title: "Nigiri Moriawase",
                content: "10 pieces of chef selection Nigiri with topping",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sscs05',
                imageSrc: menuItemDefaultImg,
                title: "Vegetable Sushi Set V",
                content: "5pcs Nigiri and Veg Sushi Roll",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sscs06',
                imageSrc: menuItemDefaultImg,
                title: "Deluxe Vegan Set V",
                content: "5 Slice of Vegan Sashimi, 4 pcs of Nigiri and 6 pcs of Veg Roll",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ], 
        'A la Carte Nigiri (2 pieces)': [
            {
                uid: 'ss_alcn01',
                imageSrc: menuItemDefaultImg,
                title: "Salmon",
                content: "",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcn02',
                imageSrc: menuItemDefaultImg,
                title: "Tuna",
                content: "",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcn03',
                imageSrc: menuItemDefaultImg,
                title: "Toro (Fatty Tuna)",
                content: "",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcn04',
                imageSrc: menuItemDefaultImg,
                title: "Seabass",
                content: "",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcn05',
                imageSrc: menuItemDefaultImg,
                title: "Seabream",
                content: "",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcn06',
                imageSrc: menuItemDefaultImg,
                title: "Scallops",
                content: "",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcn07',
                imageSrc: menuItemDefaultImg,
                title: "Turbot",
                content: "",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcn08',
                imageSrc: menuItemDefaultImg,
                title: "White Tuna (Escolar)",
                content: "",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_alcn09',
                imageSrc: menuItemDefaultImg,
                title: "Sweet Shrimp",
                content: "",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ], 
        'Sushi Roll': [
            {
                uid: 'ss_sr01',
                imageSrc: menuItemDefaultImg,
                title: "Crunchy Tuna Roll",
                content: "Salmon and asparagus roll with Tempura flakes and seared Tuna",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr02',
                imageSrc: menuItemDefaultImg,
                title: "Pham Special Roll",
                content: "Snow crab and Avocado roll wrapped in Turbot sashimi and lime topping",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr03',
                imageSrc: menuItemDefaultImg,
                title: "Rainbow Roll",
                content: "White fish and Tobiko roll wrapped in Tuna, Salmon and avocado",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr04',
                imageSrc: menuItemDefaultImg,
                title: "Flying Dragon Roll",
                content: "Eel and Asparagus roll with Tempura flakes wrapped in Avocado and Ikura on top",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr05',
                imageSrc: menuItemDefaultImg,
                title: "Samurai Roll",
                content: "Spicy Prawn tempura roll coated with Eel and jalapeno",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr06',
                imageSrc: menuItemDefaultImg,
                title: "Spicy Salmon with truffle",
                content: "Salmon with Cucumber and truffle mayo",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr07',
                imageSrc: menuItemDefaultImg,
                title: "Yellow Tail Jalapeno Roll",
                content: "Yellow Tail, Jalapeno pepper and tempura flakes",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr08',
                imageSrc: menuItemDefaultImg,
                title: "Toro Crunchy",
                content: "Big roll with minced Fatty Tuna, Tempura flakes and avocado",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr09',
                imageSrc: menuItemDefaultImg,
                title: "Negi Toro",
                content: "Minced Fatty Tuna with spring Onion",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr10',
                imageSrc: menuItemDefaultImg,
                title: "King Prawn Tempura",
                content: "Prawn Tempura with Avocado",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr11',
                imageSrc: menuItemDefaultImg,
                title: "California",
                content: "Crabstick with avocado",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr12',
                imageSrc: menuItemDefaultImg,
                title: "Soft Shell Crab",
                content: "Deep fried softshell crab with avocado and cucumber",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr13',
                imageSrc: menuItemDefaultImg,
                title: "Alaska",
                content: "Salmon, avocado and mayonnaise",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr14',
                imageSrc: menuItemDefaultImg,
                title: "Spicy Tuna Roll",
                content: "Tuna with Scallions and Spicy mayo",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr15',
                imageSrc: menuItemDefaultImg,
                title: "Mixed Vegetable V",
                content: "Mixture of Cucumber, Avocado & Asparagus",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr16',
                imageSrc: menuItemDefaultImg,
                title: "Salmon Tempura Roll",
                content: "Deep Fried Salmon with Avocado",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ss_sr17',
                imageSrc: menuItemDefaultImg,
                title: "Tuna Tempura Roll",
                content: "Deep Fried Tuna with Avocado",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ], 
    },
    'Tempura & Appetizers': { 
        '_default': [], 
        'Tempura (All Tempura dishes is containing Egg)': [
            {
                uid: 'ta_t01',
                imageSrc: menuItemDefaultImg,
                title: "Tempura Moriawase",
                content: "Mixture of Fish, Prawn & Vegetables",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_t02',
                imageSrc: menuItemDefaultImg,
                title: "Ebi Tempura",
                content: "5 piece of Prawn Tempura",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_t03',
                imageSrc: menuItemDefaultImg,
                title: "Vegetable Tempura",
                content: "",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_t04',
                imageSrc: menuItemDefaultImg,
                title: "Rock Shrimp Tempura in Spicy sauce",
                content: "Shrimp in Spicy Mayo",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_t05',
                imageSrc: menuItemDefaultImg,
                title: "Softshell Crab Tempura",
                content: "Whole Crab with vegetables",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ], 
        'Appetizers': [
            {
                uid: 'ta_a01',
                imageSrc: menuItemDefaultImg,
                title: "Edamame (Salt or Spicy) V",
                content: "",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_a02',
                imageSrc: menuItemDefaultImg,
                title: "Chicken Yakitori",
                content: "Grilled Chicken in Yakitori sauce",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_a03',
                imageSrc: menuItemDefaultImg,
                title: "Nasu Dengaku V",
                content: "Grilled Aubergine with Sweet miso",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_a04',
                imageSrc: menuItemDefaultImg,
                title: "Age dashi Tofu",
                content: "Deep fried Bean curd",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_a05',
                imageSrc: menuItemDefaultImg,
                title: "Japanese Spring Roll",
                content: "Prawn & Mushroom",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_a06',
                imageSrc: menuItemDefaultImg,
                title: "House Spring Roll",
                content: "Mince Pork & Vegetable",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_a07',
                imageSrc: menuItemDefaultImg,
                title: "Pork Gyoza",
                content: "(4 pieces)",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_a08',
                imageSrc: menuItemDefaultImg,
                title: "Chicken Gyoza",
                content: "(4 pieces)",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_a09',
                imageSrc: menuItemDefaultImg,
                title: "Deep Fried Squid Tentacles",
                content: "Served with Ponzu",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'ta_a10',
                imageSrc: menuItemDefaultImg,
                title: "Chicken Karaage",
                content: "Deep Fried Chicken",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ], 
    },
    'Salads & Soups': { 
        '_default': [], 
        'Salads': [
            {
                uid: 'saso_sa01',
                imageSrc: menuItemDefaultImg,
                title: "Seaweed Salad V",
                content: "Mixture of 4 different types of Seaweed",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'saso_sa02',
                imageSrc: menuItemDefaultImg,
                title: "Mixed Green Leaf and Avocado V",
                content: "Served with Chef Special Dressing",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'saso_sa03',
                imageSrc: menuItemDefaultImg,
                title: "Aubergine Salad",
                content: "Deep fried Aubergine with light soya",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'saso_sa04',
                imageSrc: menuItemDefaultImg,
                title: "Sashimi salads",
                content: "Mixture of sashimi, chef selection",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'saso_sa05',
                imageSrc: menuItemDefaultImg,
                title: "Salmon salad",
                content: "Seared Salmon with Chef’s dressing",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'saso_sa06',
                imageSrc: menuItemDefaultImg,
                title: "Tuna Salad",
                content: "Seared Tuna with Chef’s dressing",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ], 
        'Soups': [
            {
                uid: 'saso_s001',
                imageSrc: menuItemDefaultImg,
                title: "Miso",
                content: "Miso paste and Dashi water",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'saso_s002',
                imageSrc: menuItemDefaultImg,
                title: "Dobin Mushi",
                content: "Clear soup served in Tea pot",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'saso_s003',
                imageSrc: menuItemDefaultImg,
                title: "Vegetable Soup",
                content: "Mix leaf and soya beans",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ], 
        'Noodle Soup': [
            {
                uid: 'saso_ns01',
                imageSrc: menuItemDefaultImg,
                title: "Seafood Nabe",
                content: "Noodle soup pot with mixed seafood, egg and vegetable",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'saso_ns02',
                imageSrc: menuItemDefaultImg,
                title: "Nabeyaki Udon",
                content: "Noodle soup with prawn tempura, chicken, egg and vegetable",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'saso_ns03',
                imageSrc: menuItemDefaultImg,
                title: "Wagyu Beef Nabeyaki",
                content: "Noodle soup with Wagyu beef, Tofu, Shitake Mushroom",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ], 
    },
    'Donburi & Bento’s': { 
        '_default': [], 
        'Donburi': [
            {
                uid: 'db_d01',
                imageSrc: menuItemDefaultImg,
                title: "Chirashi",
                content: "Chef Selection of Sashimi on Sushi Rice",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_d02',
                imageSrc: menuItemDefaultImg,
                title: "Unagi Don",
                content: "Grilled eel on rice and pickles",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_d03',
                imageSrc: menuItemDefaultImg,
                title: "Tendon Mixture of tempura with rice",
                content: "",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_d04',
                imageSrc: menuItemDefaultImg,
                title: "Tori Katsudon",
                content: "Chicken in bread crumbs with Egg On rice",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_d05',
                imageSrc: menuItemDefaultImg,
                title: "Chicken Katsu Curry",
                content: "Chicken with Breadcrumbs & Curry Sauce",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_d06',
                imageSrc: menuItemDefaultImg,
                title: "Salmon Katsu Curry",
                content: "Salmon with Breadcrumbs & Curry Sauce",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_d07',
                imageSrc: menuItemDefaultImg,
                title: "House Special Fried Rice",
                content: "Chicken, shrimp and Vegetables",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_d08',
                imageSrc: menuItemDefaultImg,
                title: "Plain Boiled Rice",
                content: "",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_d09',
                imageSrc: menuItemDefaultImg,
                title: "Sushi Rice",
                content: "",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_d10',
                imageSrc: menuItemDefaultImg,
                title: "Egg Fried Rice",
                content: "",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ], 
        'Bento Box': [
            {
                uid: 'db_b01',
                imageSrc: menuItemDefaultImg,
                title: "Chicken Teriyaki Bento",
                content: "Served with Miso soup, Rice and Sashimi",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_b02',
                imageSrc: menuItemDefaultImg,
                title: "Salmon Teriyaki Bento",
                content: "Served with Miso Rice and Sashimi",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_b03',
                imageSrc: menuItemDefaultImg,
                title: "Katsu curry Bento",
                content: "Chicken Katsu with Miso Rice and Sashimi",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_b04',
                imageSrc: menuItemDefaultImg,
                title: "Tempura Bento",
                content: "Mix tempura with Miso soup, Rice and Sashimi",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_b05',
                imageSrc: menuItemDefaultImg,
                title: "Classic Bento",
                content: "Changes every month",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_b06',
                imageSrc: menuItemDefaultImg,
                title: "Premium Bento",
                content: "Changes every Month",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'db_b07',
                imageSrc: menuItemDefaultImg,
                title: "Veggie Classic bento",
                content: "Changes every month",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ], 
    },
    'Wines & Sakes': { 
        '_default': [],
        'Sake': [
            {
                uid: 'was_s01',
                imageSrc: menuItemDefaultImg,
                title: "HOT: Ozeki Ginkan (200ml & 350ml)",
                content: "",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_s02',
                imageSrc: menuItemDefaultImg,
                title: "Yoshino Tarusake (300ml)",
                content: "Sake with pleasant cedar flavour",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_s03',
                imageSrc: menuItemDefaultImg,
                title: "Kikusui Junmai Ginjo (300ml & 720 ml)",
                content: "A banana aromas and mandarin orange overtones",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_s04',
                imageSrc: menuItemDefaultImg,
                title: "Hakkaisan (300ml)",
                content: "",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_s05',
                imageSrc: menuItemDefaultImg,
                title: "Ozeki Junmai Rai (720ml)",
                content: "Traditional flavour, full-body, dry sake",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_s06',
                imageSrc: menuItemDefaultImg,
                title: "Tamanohikari Junmai Ginjo Iwai Rice (720ml)",
                content: "Made from Iwai rice & hint of Strawberry",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_s07',
                imageSrc: menuItemDefaultImg,
                title: "Shindo Uragasanryu Koka (720ml)",
                content: "Ripe pear on the nose and a refreshing finish",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_s08',
                imageSrc: menuItemDefaultImg,
                title: "Sanwa Garyubai Gohyakumangoku (720ml)",
                content: "Green grapes and pear on the nose",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_s09',
                imageSrc: menuItemDefaultImg,
                title: "Daishichi Kimoto Classic (720ml)",
                content: "Full-bodied, dry sake with rice aromas",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ], 
        'White Wine': [
            {
                uid: 'was_ww01',
                imageSrc: menuItemDefaultImg,
                title: "Goleta Sauvignon Blanc, Chile",
                content: "Full of citrus fruit moreish long finish",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_ww02',
                imageSrc: menuItemDefaultImg,
                title: "IL Narratore Pinot Grigio 2015, Veneto, Italy",
                content: "Delicate green apple flavours",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_ww03',
                imageSrc: menuItemDefaultImg,
                title: "War Horse, Chenin Blanc",
                content: "Easy drinking wine its own with the vibrancy",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_ww04',
                imageSrc: menuItemDefaultImg,
                title: "Muscadet Sevre, Domaine Derame",
                content: "Light bodied wine with clean mineral & crisp acidity",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_ww05',
                imageSrc: menuItemDefaultImg,
                title: "Picpoul de Pinet, France",
                content: "Dry white wine, peach fruit and Stoney flavours",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_ww06',
                imageSrc: menuItemDefaultImg,
                title: "Vinabade Albarino, Spain",
                content: "Notes of peach and citrus and a crisp, saline finish",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_ww07',
                imageSrc: menuItemDefaultImg,
                title: "Yowie,Sauvignon Blanc, Australian",
                content: "Tropical Aromas and a lively, fruity finish",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_ww08',
                imageSrc: menuItemDefaultImg,
                title: "Gavi di Gavi Guido Matteo, Italy",
                content: "A refreshing wine with crisp acidity",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_ww09',
                imageSrc: menuItemDefaultImg,
                title: "Chablis, Louis Robin, Burgundy",
                content: "Green Apple flavours with citrus fruit aromas",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_ww10',
                imageSrc: menuItemDefaultImg,
                title: "Sancerre 'Cuvee Classique, France",
                content: "Great finesse and beautifully expresses the varied terroirs of limestone",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_ww11',
                imageSrc: menuItemDefaultImg,
                title: "Pouilly Fuisse 'Aux Bouthieres', Burgundy",
                content: "Classic Pouilly-Fuissé with stoney aromas",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_ww12',
                imageSrc: menuItemDefaultImg,
                title: "Puligny-Montrachet, France",
                content: "Crisp and lean with hints of pure fruit Finish",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ], 
        'Red Wine': [
            {
                uid: 'was_rw01',
                imageSrc: menuItemDefaultImg,
                title: "Goleta Merlot, Chile",
                content: "Fruity red with juicy plums & Black currant",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_rw02',
                imageSrc: menuItemDefaultImg,
                title: "Valcheta Malbec, Mendoza",
                content: "Blackcurrant & hints of dark chocolate",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_rw03',
                imageSrc: menuItemDefaultImg,
                title: "Pinot Noir, Pays d'Oc, France",
                content: "Cherry, Raspberry and Strawberry hints",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_rw04',
                imageSrc: menuItemDefaultImg,
                title: "Cotes du Rhone, Organic, France",
                content: "Fresh, Fruity and Easy Drinking",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_rw05',
                imageSrc: menuItemDefaultImg,
                title: "Trois Maisons Rouge, Organic Bordeaux, France",
                content: "Trois juicy red fruit",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_rw06',
                imageSrc: menuItemDefaultImg,
                title: "Chateau du Puy Bonnet, Bordeaux",
                content: "Plum and Blackberry with a hint of spice",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_rw07',
                imageSrc: menuItemDefaultImg,
                title: "Ad Libitum Monastel De Rioja",
                content: "Smooth vanilla, Black cherries, and earthy aromas",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_rw08',
                imageSrc: menuItemDefaultImg,
                title: "Chateau d'Arcole, Saint-Emilion",
                content: "Plums and spice, full flavoured & excellent length",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_rw09',
                imageSrc: menuItemDefaultImg,
                title: "Gevrey-chambertin les Crais, France",
                content: "Single vineyard Gevrey Chambertin from Gerard Seguin",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ],
        'Rose Wine': [
            {
                uid: 'was_row01',
                imageSrc: menuItemDefaultImg,
                title: "Mon Rose de Montrose",
                content: "Flavours of Strawberry and Raspberry",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
        ],
        'Sparkling Wine': [
            {
                uid: 'was_spw01',
                imageSrc: menuItemDefaultImg,
                title: "Vino Spumante Prosecco",
                content: "",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_spw02',
                imageSrc: menuItemDefaultImg,
                title: "Prosecco, Enrico Bedin",
                content: "Fresh flavours of ripe apple",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            },
            {
                uid: 'was_spw03',
                imageSrc: menuItemDefaultImg,
                title: "Moet Chandon Brut Imperial N.V.",
                content: "Champagne with a seductive palate and elegant finish",
                price: "$3.99",
                rating: "3.9",
                reviews: "26",
                url: '/reservation'
            }
        ] 
    }
};