import axios from "axios";
import { CONTACT_US_EMAIL_TEMP, DATE_TIME_FORMAT, RESERVATION_EMAIL_TEMP } from "pham-sushi/helpers/constants";
import { titleCase } from "pham-sushi/helpers/utils";

export const sendMail = (formData, templateId) => {
    const data = JSON.stringify({
        receiver: formData.email,
        subject: getSubject(formData, templateId),
        content: getContent(formData, templateId),
        ccSubject: getCcSubject(formData, templateId),
        ccContent: getCcContent(formData, templateId),
        isImportant: getIsImportant(formData, templateId)
    });

    return axios.post(
        getServiceEndpoint(templateId), 
        data, 
        {
            headers: { 
                'x-api-key': process.env.REACT_APP_OMS_API_KEY, 
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            }
        });
};

const getServiceEndpoint = (templateId) => {
    if (templateId === CONTACT_US_EMAIL_TEMP) {
        return  `${process.env.REACT_APP_OMS_URL}/contact-us-confirmation`;
    } else if (templateId === RESERVATION_EMAIL_TEMP) {
        return  `${process.env.REACT_APP_OMS_URL}/booking-confirmation`;
    }
}

const getSubject = (formData, templateId) => {
    if (templateId === CONTACT_US_EMAIL_TEMP) {
        return "Pham Sushi | Thank you for contacting us";
    } else if (templateId === RESERVATION_EMAIL_TEMP) {
        return "Pham Sushi - The Heron | Reservation Confirmation";
    }
};

const getCcSubject = (formData, templateId) => {
    if (templateId === CONTACT_US_EMAIL_TEMP) {
        return `[Contact Us] New message from ${titleCase(formData.name)} (${formData.email} | ${formData.phone})`;
    } else if (templateId === RESERVATION_EMAIL_TEMP) {
        return `[Reservation # ${formData.time.format(DATE_TIME_FORMAT)}] New reservation for ${formData.numberOfPeople} table from ${titleCase(formData.name)} (${formData.email} | ${formData.phone})`;
    }
};

const getContent = (formData, templateId) => {
    if (templateId === CONTACT_US_EMAIL_TEMP) {
        return `
        Dear ${titleCase(formData.name)},

        Thank you for contacting us!

        We are constantly striving to provide the highest quality service and care deeply about how our work affects customers like you, and your feedback helps us do so.
        
        Thank you for taking the time out of your day. We really appreciate it!

        
        - Pham Sushi -
        • The Heron: 5 Moor Lane, EC2Y 9AP | (020) - 7638 - 6668
        • Whitecross: 159 Whitecross Street, EC1Y 8JL | (020) - 7251 - 6336
        • Take Away: 159 Whitecross Street, EC1Y 8JL | (020) - 7253 - 8658, (020) - 7251 - 6336`;
    } else if (templateId === RESERVATION_EMAIL_TEMP) {
        return `
        Dear ${titleCase(formData.name)},
        
        It’s confirmed, we’ll see you on [${formData.time.format(DATE_TIME_FORMAT)}]! Thank you for booking a table for [${formData.numberOfPeople}] with us on Pham Sushi - The Heron.
        
        If you need to get in touch, you can email or phone us directly. We will also update you of any changes.
        
        Thank you for choosing us! We look forward to welcoming you soon!
        
        
        - Pham Sushi -
        • The Heron: 5 Moor Lane, EC2Y 9AP | (020) - 7638 - 6668
        • Whitecross: 159 Whitecross Street, EC1Y 8JL | (020) - 7251 - 6336
        • Take Away: 159 Whitecross Street, EC1Y 8JL | (020) - 7253 - 8658, (020) - 7251 - 6336`;
    }
};

const getCcContent = (formData, templateId) => {
    if (templateId === CONTACT_US_EMAIL_TEMP) {
        return `
        Message from ${titleCase(formData.name)} (${formData.email} | ${formData.phone}):
            
            ${formData.subject || ''}
            ${formData.message  || ''}`;
    } else if (templateId === RESERVATION_EMAIL_TEMP) {
        return `
        Reservation [${formData.time.format(DATE_TIME_FORMAT)}]
        
        * Number of People: ${formData.numberOfPeople}
        
        * Customer Name: ${titleCase(formData.name)}
        * Customer Email: ${formData.email}
        * Customer Phone No: ${formData.phone}
        
        * Notes: ${formData.notes  || ''}`;
    }
};

const getIsImportant = (formData, templateId) => {
    return templateId === RESERVATION_EMAIL_TEMP && parseInt(formData.numberOfPeople.replace(/\D/g, '')) >= 5;
};
