/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAnnouncement = /* GraphQL */ `
  query GetAnnouncement($id: ID!) {
    getAnnouncement(id: $id) {
      id
      content
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAnnouncements = /* GraphQL */ `
  query ListAnnouncements(
    $filter: ModelAnnouncementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnnouncements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClosedDate = /* GraphQL */ `
  query GetClosedDate($id: ID!) {
    getClosedDate(id: $id) {
      id
      date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClosedDates = /* GraphQL */ `
  query ListClosedDates(
    $filter: ModelClosedDateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClosedDates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
