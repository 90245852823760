import tw from "twin.macro";
import styled from "styled-components";
import Slider from "react-slick";

export const SlickSlider = styled(Slider)`
.slick-dots {
    li {
        button:before {
            ${tw`text-gray-300 text-xxs`}
        }
        &.slick-active {
            button:before {
                ${tw`text-orange`}
            }
        }
    }
}
`;