import React, { useEffect, useMemo, useState } from "react";
import tw from "twin.macro";
import InputForm from "pham-sushi/components/TwoColWithForm";
import ReservationImage from "images/rps-make-reservation.jpg";
import { sendMail } from "pham-sushi/services/mail.service";
import { RESERVATION_EMAIL_TEMP } from "pham-sushi/helpers/constants";
import { generateClient } from "aws-amplify/api";
import { listClosedDates } from "graphql/queries";

const client = generateClient();

const imageCss = tw`lg:mr--32`;

export default () => {
  const [closedDates, setClosedDates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (value) => {
    await sendMail(value, RESERVATION_EMAIL_TEMP);
    return true;
  };
  const reservationDesc = `
                            Reservation is only available at The Heron - 5 Moor Lane, London, EC2Y 9AP.

                            The Heron: (020) - 7638 - 6668                            
                            Monday - Friday: 12.00 - 15.00 & 17.00 - 22.00
                            Saturday: 17.30 - 22.00
                            * Booking windows to be closed for 15.00-16.00
                            * Restaurant will be closed on all Bank Holiday
                            Email: theheron@phamsushi.com
                            `;
  useEffect(() => {
    let isUnmounted = false;
    // Get all closed dates
    try {
      const getData = async () => {
        const allClosedDates = await client.graphql({
          query: listClosedDates,
        });
        const data = allClosedDates?.data?.listClosedDates?.items?.[0];
        if (!isUnmounted) {
          setClosedDates(data && data.date ? data.date.split(",") : []);
        }
      };
      setIsLoading(true);
      getData();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
    return () => {
      isUnmounted = true;
    };
  }, []);

  const fields = useMemo(
    () => [
      {
        type: "text",
        name: "name",
        placeholder: "Your Full Name",
        required: true,
      },
      {
        type: "text",
        name: "phone",
        placeholder: "Your Mobile Phone",
        required: true,
      },
      {
        type: "email",
        name: "email",
        placeholder: "Your Email",
        required: true,
      },
      {
        type: "datetime",
        name: "time",
        placeholder: "Date & Time",
        required: true,
        holidays: closedDates,
        disabled: isLoading,
      },
      {
        type: "select",
        name: "numberOfPeople",
        placeholder: "Number of People",
        options: [
          "1 people",
          "2 people",
          "3 people",
          "4 people",
          "5 people",
          "6 people",
          "Over 6 people",
        ],
        required: true,
      },
      {
        type: "textarea",
        name: "notes",
        placeholder: "Notes",
        required: false,
      },
    ],
    [closedDates, isLoading]
  );

  return (
    <>
      <InputForm
        subheading="Make Reservation"
        heading={<>Feel free to get in touch with us.</>}
        description={reservationDesc}
        submitButtonText="Reserve"
        imageSrc={ReservationImage}
        fields={fields}
        onSubmit={handleSubmit}
        imageCss={imageCss}
        errorMessage="Your reservation is not saved"
        successMessage="Your reservation is save successfully!"
      />
    </>
  );
};
