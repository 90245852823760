
export const truncateFullWord = (str, maxLen, separator = ' ') => {
    if (str.length <= maxLen) return null;
    return str.substr(0, str.lastIndexOf(separator, maxLen)) + '...';
};

export const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
}

export const titleCase = (str) => {
    return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ').trim();
}
