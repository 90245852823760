import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { LinkButton } from "components/misc/Buttons.js";
import headerBgImage from "images/sushi/header-bg.png";
import { SlickSlider } from "pham-sushi/misc/slider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from "images/sushi/we-are-1.jpg";
import image2 from "images/sushi/we-are-2.jpg";
import image3 from "images/sushi/we-are-3.jpg";
import image4 from "images/sushi/we-are-4.jpg";


const Container = tw.div`relative pb-8 mt-20 lg:mt-28`;
const WeAreSlider = styled(SlickSlider)`
    .slick-dots {
        bottom: -2rem;
    }
`;
const SlideImage = styled.div(props => [
    css`
        padding-top: calc(732/1920*100%);
        background-size: ${props.size ?? '100% auto'};
        background-position: ${props.position ?? 'center'};
        background-repeat: no-repeat;
        background-image: url(${props.image});
    `
]);
const TextContent = tw.div`text-center mb-22`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const Heading = styled(
    SectionHeading
)`
    ${css`
        font-family: "Cezanne";
        &::before {
            background-image: url(${headerBgImage});
            background-position: right top;
            background-repeat: no-repeat;
            background-size: auto 100%;
            content: '';
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 246%;
            top: -80%;
            right: 14%;
        }
    `}
    ${tw`mt-4 relative whitespace-nowrap inline-block text-white text-left text-4xl lg:text-8xl font-normal text-center md:text-left leading-tight`}
`;
const Description = tw.p`mt-16 mx-auto text-center max-w-5xl lg:text-2xl leading-relaxed text-gray-400`;

export default ({
    subheading = "",
    heading = (
        <>
            Pham Sushi
        </>
    ),
    description = "Pham Sushi has been providing authentic Japanese Cuisine for more than 20 years.",
    primaryButtonText = null,
    primaryButtonUrl = "#"
}) => {
    // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        nextArrow: <></>,
        prevArrow: <></>
    };
    return (
        <Container>
            <TextContent>
                <Subheading>{subheading}</Subheading>
                <Heading>{heading}</Heading>
                <Description>{description}</Description>
                {primaryButtonText &&
                    <LinkButton to={primaryButtonUrl}>{primaryButtonText}</LinkButton>
                }
            </TextContent>
            <WeAreSlider {...settings}>
                <SlideImage image={image1} />
                <SlideImage image={image2} />
                <SlideImage image={image3} position='center bottom -3rem'/>
                <SlideImage image={image4} />
            </WeAreSlider>
        </Container>
    );
};
